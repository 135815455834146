<template>
  <div class="view-classificacao">
    <the-toolbar title="Classificação TamoJunto"/>
    <div class="pa-8">
       <h1>Meus resultados</h1>
      <p class="font-weight-regular title mt-6">
        Olá, <b class="text-capitalize">{{user.profile.nome.split(' ').slice(0, 1).toString().toLowerCase()}}</b>!
      </p>

      <p v-if="classificacao">
        Você alcançou <b :class="classeCorTexto">{{ textoVidasConfirmadas }}</b>.<br>
        Sua classificação atual no <b>Programa TamoJunto</b> é <b :class="classeCorTexto">{{ user.profile.classificacao }}</b>
      </p>
      <p v-else>
        No momento, você não tem classificação no <b>Programa TamoJunto.</b><br>
        Turbine suas vendas para participar.
      </p>

      <grafico-classificacao :classificacao="classificacao"/>

      <p class="mt-4">Período apurado: <b>{{ periodoApuracao }}</b></p>
      <div class="d-flex flex-column">
        <button-acesso-tamo-junto class="mt-4" redirecionaPara="/conheca-o-programa" botao="conheca">CONHEÇA O PROGRAMA</button-acesso-tamo-junto>
        <button-acesso-tamo-junto class="mt-4" redirecionaPara="/beneficios" botao="entenda">ENTENDA AS CLASSIFICAÇÕES</button-acesso-tamo-junto>
        <button-acesso-tamo-junto class="mt-4" botao="acesse">ACESSE O PROGRAMA</button-acesso-tamo-junto>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import usuarioService from '@/services/usuarioService';
import TheToolbar from '@/components/TheToolbar.vue';
import GraficoClassificacao from './GraficoClassificacao.vue';
import ButtonAcessoTamoJunto from './ButtonAcessoTamoJunto.vue';

export default {
  components: { TheToolbar, GraficoClassificacao, ButtonAcessoTamoJunto },

  data() {
    return {
      dadosClassificacao: null,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),

    classificacao() {
      const dictionary = {
        Experiente: 'EXPERIENTE',
        Especialista: 'ESPECIALISTA',
        Elite: 'ELITE',
        Imbatível: 'IMBATIVEL',
        Platinum: 'PLATINUM',
      };
      return dictionary[this.user.profile.classificacao] || '';
    },

    textoVidasConfirmadas() {
      if (!this.dadosClassificacao) return '';
      const qtdVidas = this.dadosClassificacao.vidasConfirmadas;
      if (qtdVidas === 1) return '1 vida confirmada';

      return `${qtdVidas} vidas confirmadas`;
    },

    classeCorTexto() {
      const dictionary = {
        Experiente: 'cor-texto--experiente',
        Especialista: 'cor-texto--especialista',
        Elite: 'cor-texto--elite',
        Imbatível: 'cor-texto--imbativel',
        Platinum: 'cor-texto--imbativel',
      };
      return dictionary[this.user.profile.classificacao] || '';
    },

    periodoApuracao() {
      if (!this.dadosClassificacao) return '';
      const { inicioPeriodo, fimPeriodo } = this.dadosClassificacao;
      moment.locale('pt-br');
      return `${moment(inicioPeriodo).subtract("month").startOf("day").format('MMMM/yyyy')} a ${moment(fimPeriodo).subtract("month").startOf("day").format('MMMM/yyyy')}`;
    },
  },

  created() {
    this.buscarDadosClassificacao();
  },

  methods: {
    async buscarDadosClassificacao() {
      this.dadosClassificacao = await usuarioService.buscarDadosClassificacao();
    },
  },
};
</script>

<style lang="scss" scoped>
  .view-classificacao {

    .cor-texto {
      &--experiente {
        color: #0033CC;
      }

      &--especialista {
        color: #FF3300;
      }

      &--elite {
        color: #660099;
      }

      &--imbativel {
        color: #000066;
      }
    }
  }
</style>
