<template>
  <v-btn
    outlined
    color="primary"
    max-width="300"
    :loading="loading"
    @click="evClick"
    ><slot></slot
  ></v-btn>
</template>

<script>
import usuarioService from "@/services/usuarioService";

const URL_TAMOJUNTO = process.env.VUE_APP_BASE_URL_TAMOJUNTO;

export default {
  props: {
    redirecionaPara: {
      type: String,
      default: "/",
    },
    botao: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async evClick() {
      if (this.loading) return;
      else this.loading = true;

      await usuarioService.criaLogTamoJunto({ botao: this.botao });
      const token = encodeURIComponent(
        localStorage.getItem("auth_corretor_qvenda").replace("Bearer ", "")
      );
      const redirect = this.redirecionaPara;
      await window.open(
        `${URL_TAMOJUNTO}#/login-automatico?token=${token}&redirect=${redirect}`
      );
      this.loading = false;
    },
  },
};
</script>
