var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-classificacao" },
    [
      _c("the-toolbar", { attrs: { title: "Classificação TamoJunto" } }),
      _c(
        "div",
        { staticClass: "pa-8" },
        [
          _c("h1", [_vm._v("Meus resultados")]),
          _c("p", { staticClass: "font-weight-regular title mt-6" }, [
            _vm._v(" Olá, "),
            _c("b", { staticClass: "text-capitalize" }, [
              _vm._v(
                _vm._s(
                  _vm.user.profile.nome
                    .split(" ")
                    .slice(0, 1)
                    .toString()
                    .toLowerCase()
                )
              ),
            ]),
            _vm._v("! "),
          ]),
          _vm.classificacao
            ? _c("p", [
                _vm._v(" Você alcançou "),
                _c("b", { class: _vm.classeCorTexto }, [
                  _vm._v(_vm._s(_vm.textoVidasConfirmadas)),
                ]),
                _vm._v("."),
                _c("br"),
                _vm._v(" Sua classificação atual no "),
                _c("b", [_vm._v("Programa TamoJunto")]),
                _vm._v(" é "),
                _c("b", { class: _vm.classeCorTexto }, [
                  _vm._v(_vm._s(_vm.user.profile.classificacao)),
                ]),
              ])
            : _c("p", [
                _vm._v(" No momento, você não tem classificação no "),
                _c("b", [_vm._v("Programa TamoJunto.")]),
                _c("br"),
                _vm._v(" Turbine suas vendas para participar. "),
              ]),
          _c("grafico-classificacao", {
            attrs: { classificacao: _vm.classificacao },
          }),
          _c("p", { staticClass: "mt-4" }, [
            _vm._v("Período apurado: "),
            _c("b", [_vm._v(_vm._s(_vm.periodoApuracao))]),
          ]),
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "button-acesso-tamo-junto",
                {
                  staticClass: "mt-4",
                  attrs: {
                    redirecionaPara: "/conheca-o-programa",
                    botao: "conheca",
                  },
                },
                [_vm._v("CONHEÇA O PROGRAMA")]
              ),
              _c(
                "button-acesso-tamo-junto",
                {
                  staticClass: "mt-4",
                  attrs: { redirecionaPara: "/beneficios", botao: "entenda" },
                },
                [_vm._v("ENTENDA AS CLASSIFICAÇÕES")]
              ),
              _c(
                "button-acesso-tamo-junto",
                { staticClass: "mt-4", attrs: { botao: "acesse" } },
                [_vm._v("ACESSE O PROGRAMA")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }