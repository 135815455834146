var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        outlined: "",
        color: "primary",
        "max-width": "300",
        loading: _vm.loading,
      },
      on: { click: _vm.evClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }