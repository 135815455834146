var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grafico-classificacao" }, [
    _vm.classificacao === _vm.classificacoes.EXPERIENTE
      ? _c("img", {
          attrs: {
            src: require("@/assets/img/classificacao/grafico-experiente.png"),
            alt: "gráfico classificação experiente",
          },
        })
      : _vm.classificacao === _vm.classificacoes.ESPECIALISTA
      ? _c("img", {
          attrs: {
            src: require("@/assets/img/classificacao/grafico-especialista.png"),
            alt: "gráfico classificação especialista",
          },
        })
      : _vm.classificacao === _vm.classificacoes.ELITE
      ? _c("img", {
          attrs: {
            src: require("@/assets/img/classificacao/grafico-elite.png"),
            alt: "gráfico classificação elite",
          },
        })
      : _vm.classificacao === _vm.classificacoes.IMBATIVEL
      ? _c("img", {
          attrs: {
            src: require("@/assets/img/classificacao/grafico-imbativel.png"),
            alt: "gráfico classificação imbativel",
          },
        })
      : _c("img", {
          attrs: {
            src: require("@/assets/img/classificacao/grafico-sem-classificacao.png"),
            alt: "gráfico sem classificação",
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }