<template>
  <div class="grafico-classificacao">
    <img v-if="classificacao === classificacoes.EXPERIENTE" src="@/assets/img/classificacao/grafico-experiente.png" alt="gráfico classificação experiente"/>
    <img v-else-if="classificacao === classificacoes.ESPECIALISTA" src="@/assets/img/classificacao/grafico-especialista.png" alt="gráfico classificação especialista"/>
    <img v-else-if="classificacao === classificacoes.ELITE" src="@/assets/img/classificacao/grafico-elite.png" alt="gráfico classificação elite"/>
    <img v-else-if="classificacao === classificacoes.IMBATIVEL" src="@/assets/img/classificacao/grafico-imbativel.png" alt="gráfico classificação imbativel"/>
    <img v-else src="@/assets/img/classificacao/grafico-sem-classificacao.png" alt="gráfico sem classificação"/>
  </div>
</template>

<script>

const CLASSIFICACOES = {
  SEM_CLASSIFICACAO: '',
  EXPERIENTE: 'EXPERIENTE',
  ESPECIALISTA: 'ESPECIALISTA',
  ELITE: 'ELITE',
  IMBATIVEL: 'IMBATIVEL',
  PLATINUM: 'PLATINUM',
};

export default {
  props: {
    classificacao: {
      type: String,
      default: '',
      validator(value) { return Object.values(CLASSIFICACOES).includes(value); },
    },
  },

  data() {
    return {
      classificacoes: CLASSIFICACOES,
    };
  },
};
</script>

<style lang="scss" scoped>
  .grafico-classificacao {
    max-width: 600px;

    &>img {
      width: 100%;
    }
  }
</style>
